import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject ,Observable} from 'rxjs';

export interface AppConfig {
    inputStyle: string;
    colorScheme: string;
    theme: string;
    ripple: boolean;
    menuMode: string;
    scale: number;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'lara-light-indigo',
        scale: 14,
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false
    };
    openSideMenu = false;


    private tabTitle = new Subject<any>();
    getTabTitle = this.tabTitle.asObservable();
    BCtabTitle: string;
    BCtabTitleObject: any;

    private newMsgValue = new Subject<any>();
    getnewMsgValue = this.newMsgValue.asObservable();


    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    newMsg(newMsgData: any) {
        this.newMsgValue.next(newMsgData);
    }



    setBCTitle(BCTitle: string, loading?) {
        if (!loading) {
            loading = false
        }
        this.BCtabTitle = BCTitle;
        this.BCtabTitleObject = { title: BCTitle, loading: loading };
        this.tabTitle.next({ title: BCTitle, loading: loading });
    }

    Loading(loading) {
        this.BCtabTitleObject = { title: this.BCtabTitle, loading: loading };
        this.tabTitle.next({ title: this.BCtabTitle, loading: loading });
    }
    
    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;
            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        }
        else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    showProfileSidebar() {
        this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
        if (this.state.profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this.configUpdate.next(this.config);
    }


    _lang = localStorage.getItem('lang') || 'ar';
    private langSource = new BehaviorSubject(this._lang);

    currentLang = this.langSource.asObservable();

    constructor() {
        this.changeLang(this.readLastLang());
    }

    changeLang(lang: any) {
        this.langSource.next(lang)
    }

    readLastLang = (): string => {
        try {
            let l = JSON.parse(localStorage.getItem('lang'));
            if (l != undefined || l != null) {
                return l;
            }

        } catch {
            return this._lang
        }
        return this._lang
    }
    private apiCallStatus = new Subject<any>();

    setData(data: any) {
      this.apiCallStatus.next(data);
    }
  
    getData(): Observable<any> {
      return this.apiCallStatus.asObservable();
    }
}
