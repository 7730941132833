
export const environment = {
  production: false,
   appUrl: '',
  //appUrl: 'https://localhost:44389/gateway/',
  // G-3PLLH5GTG4    haeaselhodod@gmail.com
  // G-XF9WPDRV1C
  google_trackingId: 'G-3PLLH5GTG4',
  
  RecordPerPage: 8,
  surveyViewName:'view',
  ReferenceId: "TWdTTjF4ZTNUSkU9",  //"THJFOTBjWHdwL289",

  
  dynamicFormsIds: {
    mainSlider: {formId:'V0hIZkZsZXkrc3c9',entityId:'cWxwODhJbmppdDA9'},
  },
  

  Entities: {
    careers: "Qko3OWdIeXhucE09",
    News: "THJFOTBjWHdwL289",
    Articles: "OVJyQlRlWG1MRkk9",
    Users: "UjI2SHNJa1B1Tjg9",
    MainSlider: "QW4rcEpGa095b009",
    Multimedia: "cnlJNDV4djlFaU09",
    Videos: "SHMydWNPTG9MWWs9",
    Menus: "VzNnczNwRmNjSHc9",
    Departments: "TFdxZDZaa3lWakE9",
    Municipalities: "c3FQY0pTR293R289",
    MobileApps: "ZnlSNEFSTXB1Y3M9",
    Advertisements: "d0xjdHIyWWNpelU9",
    InitiativeProjects: "ZlVEanRXb1BYeWM9",
    ExternalSites: "di82UXYzS0VWaEE9",
    Officials: "a3YxcHVRbkJPQnc9",
    Mayors: "SStEZ2F3VFJqbE09",
    Princes: "YWdUZXFBVEZSbDA9",
    Awards: "azUzV2NNQURraHc9",
    Partners: "NWk4dDJsRFlRR289",
    LocalPartners: "aWs0ZmV2bmE3YnM9",
    Documents: "NlVWeTFaM3B5K1U9",
    Eservices: "ZUtTR0hTSWJURlE9",
    GovServices: "MXo5aC9FWlgxbXM9",
    Regulations: "THhYaEQ1MU9RNkU9",
    InvestmentsOpportunities: "YTE3SmFWaDRlelU9",
    InvestmentsCompetitions: "aEh6Z0ZocXJhVUE9",
    ContactUs: "c0YwZW9YamtMTmM9",
    ContactMayor: "WGpJL25RM3JWV2c9",
    Contact940: "UU45Y2s3OVNyMkU9",
    SpNeedsContacts: "d3FTMUFaSzlta0k9",
    Comments: "NXhKY1pRRlJBaWs9",
    Rates: "U2FQVlZlWmJHbUk9",
    SubMenu: "OVJrVjFwOWJXa0k9",
    Versions: "NlVWeTFaM3B5K1U9",
    Magazine: "K0FBUE9pMWZBa0U9",
    Search: "S3IzWFdtYS9NdzA9",
    Volunteers: "Y3prR2VEcGRPU0U9",
    //Surveys: "c2IvZGdYMlZXM1E9",

    LatestUpdates: "d1EzemJqZERpOUU9",
    Innovations: "KzhjaUFlOVFtNkE9",
    EidFitr: "TVJsZisxeXp1SWc9",
    GardensCompetitions: "UXg5Tjc0TnNUM3M9",
    Agencies: "T05tc3EyWmIvVXc9",
    Complaint: "UmRvN0pjTnV0Qk09",
    Suggestions: "OXFjKzNwblZGQVk9",
    Inquiries: "V2ZaU1NqZVN1UGs9",
    OpenDataRequest: "c0YwZW9YamtMTmM9",
    Corruption: "V1lMK3NoSGMzd3c9",
    CivilSocietyPartners: "b0ZqYzYyTE45azQ9",
    PrivateSectorPartners: "WXlpTXo4aFdlQ0k9",
    AcademicInstitutionsPartners: "TDNnMzJpajRLbDA9",
    GovernmentOrganizationsPartners: "c1EvSFFnVkVrTnc9",
    EntrancePermits: "R3dqUCtFYjQ1UXc9",
    ImageGallery: "cnlJNDV4djlFaU09",
    FAQ: "WDRLM2kxbVkrQnc9",
    OpenDataManagement: "V0hIZkZsZXkrc3c9",
    Statistics: "T0ZXa3NQTCtzZDQ9",
    PersonalPermit: "YjREN3VEVmZPcFE9",
    FollowPersonalPermit: "endPZlh5bnpyeTA9",
    CarPermit: "WW1JaVkyZERHRTg9",
    FollowCarPermit: "bXlqTkt1ZzNmU0U9",
    CompanyInfo: "WEZVb1B5RXo3SzA9",
    Platforms:"MWFVekd2c0RLczQ9",
    ScientificLetters:"YlVRd1ZEU2ZueFk9",
    NavalAcademy:'OG0xRjd5bGN1WEU9',
    FormsSurvey:"T09wNlhhNU9CTTA9",
    MarinMedia:"YVB6WGJ6d2lQT2M9",
    Events:"dmUxVmw0bVozRVE9",
    MedicalServices:"bkZSTklVd2ZGRWc9"


  },

  PermissionLevels: {
    Add: "NXhKY1pRRlJBaWs9",
    Edit: "NXhKY1pRRlJBaWs9",
    Delete: "VzNnczNwRmNjSHc9",
    Activate: "OVJrVjFwOWJXa0k9",
    List: "THJFOTBjWHdwL289",
    View: "THJFOTBjWHdwL289",
    Reports: "U2FQVlZlWmJHbUk9"
  },

  EntitiesFrontIdentity: {
  
    FormsSurvey: "FormsSurvey",
  
  },
  counUpSurvay:100,

  tabTitle:{
    Login: {ar:'تسجيل الدخول',en:'Login'},
 
    Surveys:  {ar:'الاستبيانات',en:'Surveys'},
    Home:'Home page || Tafra',
    about:'About || Tafra',
    Products:'Products || Tafra',
    projects:'Projects || Tafra',
    Contact:'Contact || Tafra',



   
  },



 

 




  HijriDateOptions: {
    fullDate: 1,
    year: 2,
    monthYear: 3,
    day: 4
  },


     innerReferance:'THJFOTBjWHdwL289',
     PortalReferance:'THJFOTBjWHdwL289',



  recaptcha: {
   // siteKey: '6Ldg3jImAAAAAH8YZ0bzhvwQ0VKqtzlzOHD_WQRt',

   siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },

 







};

