import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject ,Observable} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    token = '';
    routingAfterLogin=''
    APIURL = environment.appUrl;
    UserLoginRole: string = "";
    constructor(private http: HttpClient,public router: Router, private translate: TranslateService,private activatedRoute: ActivatedRoute) { }


    //   Get
    get(url: string) {
        return this.http.get<any>(`${this.APIURL}${url}`);
    }

    //   Post
    postLogin(url: string, body: any, header: any) {
        return this.http.post<any>(`${this.APIURL}${url}`, body, { headers: header });

    }
    post(url: string, body: any) {
        // this.scrollUp();
        return this.http.post<any>(`${this.APIURL}${url}`, body);

    }
    //   Put
    put(url: string, body: any) {
        return this.http.put<any>(`${this.APIURL}${url}`, body);

    }
    //   Delete

    delete(url: string) {
        return this.http.delete<any>(`${this.APIURL}${url}`);
    }

    getToken() {
        const _token = localStorage.getItem('token')?.split('"').join('');
        return _token;
    }

    downloadFile(url,downloadName){
       this.http.get(url, { responseType: 'blob' })
      .subscribe(blob => {
        const link = document.createElement('a');
        link.href =  window.URL.createObjectURL(blob);
        link.download = downloadName;
        link.target="_blank"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }

    base64ToBlob(b64Data, sliceSize = 512) {
        let byteCharacters = atob(b64Data); //data.file there
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {

        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
    }


    convertDateToHijri(lang: string, inputDate?: any) {

        var date = inputDate || new Date();
        lang = lang || 'en';
         const hijri_Date = new Intl.DateTimeFormat(lang + '-u-ca-islamic', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            calendar: 'islamic-civil',
          }).format(date);

        return hijri_Date;
    }

    

    getYearFromHijriDate(lang: string, inputDate?: any) {

        var date = inputDate || new Date();
        lang = lang || 'en';
        var options = {
            year: 'numeric'
        };

        let hijriDate = date.toLocaleString(lang + '-u-ca-islamic', options);
        return hijriDate;
    }
    getMonthYearFromHijriDate(lang: string, inputDate?: any) {

        var date = inputDate || new Date();
        lang = lang || 'en';
        var options = {
            year: 'numeric', month: 'long'
        };

        let hijriDate = date.toLocaleString(lang + '-u-ca-islamic', options);
        return hijriDate;
    }

    getDayMonthFromHijriDate(lang: string, inputDate?: any) {

      var date = inputDate || new Date();
      lang = lang || 'en';
      var options = {
          day: 'numeric', month: 'long'
      };

      let hijriDate = date.toLocaleString(lang + '-u-ca-islamic', options);
      return hijriDate;
  }
    getDayFromHijriDate(lang: string, inputDate?: any) {

        var date = inputDate || new Date();
        lang = lang || 'en';
        var options = {
            day: 'numeric'
        };

        let hijriDate = date.toLocaleString(lang + '-u-ca-islamic', options);
        return  hijriDate;
    }

    calculateDiff(startingDate, endDate) {
        endDate = new Date(endDate);
        startingDate = new Date(startingDate);

        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate())) / (1000 * 60 * 60 * 24));
    }
    GetArticleContent(body) {
        return this.http.post<any>(`${this.APIURL}` + "articles/GetArticleDetails", body);
    }

    download(receivedFile) {
        this.getBase64FromUrl(receivedFile).then((fileContent: String) => {
            fileContent = fileContent.split(',')[1]
            const linkSource = `${fileContent}`;
            const downloadLink = document.createElement('a');
            const fileName = new Date().toISOString() + '.pdf';

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });

    }

    getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);



            }
        });
    }
    public   currentLang: string;

    public getCurrentLanguage(): string {
      const pathSegments = window.location.pathname.split('/');
      const languageSegment = pathSegments[1];
    
      if (languageSegment === 'en' || languageSegment === 'ar') {
        return languageSegment;
      }
    
      const storedLanguage = localStorage.getItem('lang');
    
      return storedLanguage || 'ar';
    }
    
    private applyLanguageStyles(): void {
      const bodyElement = document.querySelector('body');
      if(this.currentLang === 'ar'){
        bodyElement.classList.add('rtl');

      }else{
        bodyElement.classList.add('english');

      }
    //   bodyElement.setAttribute('dir', this.currentLang === 'ar' ? 'rtl' : 'ltr');
    }
    
    private setLanguageData(): void {
      this.translate.use(this.currentLang);
      this.setLanData({ lang: this.currentLang });
      localStorage.setItem('lang', this.currentLang);
    }
    
    private redirectToCorrectLanguage(): void {
      if (!window.location.pathname.startsWith(`/${this.currentLang}`)) {
        const newUrl = `/${this.currentLang}${window.location.pathname}`;
        this.router.navigate([newUrl]);
      }
    }
    
    public langCondition(): void {
      this.currentLang = this.getCurrentLanguage();
      this.applyLanguageStyles();
      this.setLanguageData();
      // this.redirectToCorrectLanguage();
    }


   public  checkedUserLoggedByAD(){
        if (localStorage.getItem('userInfo')) {
            return localStorage.getItem('userInfo');
          }
          return this.http.post<any>(`${this.APIURL}` +'users/CheckUserLogged', {})
          .subscribe(response => {
            localStorage.setItem('userInfo', response.output.UserEntity)
             
          })
        }

    public async generateToken() {

      
       
        // const dom: any = document.querySelector('body');
        // if(window.location.href.search("/en") > 0){
        //     this.currentLang = 'en'
        // }
        // if(window.location.href.search("/ar") > 0){
        //     this.currentLang = 'ar'
        // }
        // if(!this.currentLang){
        //     this.currentLang = localStorage.getItem('lang') || 'ar';
        // }
        // if(this.currentLang == 'ar' ){
        //     dom.classList.add('rtl');
        // }
        // if( this.currentLang == 'en'){
        //     dom.classList.add('english');
        // }
        // this.translate.use(this.currentLang);
        // this.setLanData({ lang: this.currentLang });
        // localStorage.setItem('lang', this.currentLang);
        // if(!window.location.pathname.startsWith('/ar') && !window.location.pathname.startsWith('/en')){
        //     const newUrl = '/'+this.currentLang +window.location.pathname;
        //     this.router.navigate([newUrl]);
        // }

        // this.langCondition();
        // if (localStorage.getItem('token')) {
        //   return localStorage.getItem('token');
        // }
        // const response = await this.http.post<any>(`${this.APIURL}` +'users/GenerateToken', {}).toPromise();
        // localStorage.setItem('token', response.output.UserJWT)
        // this.token = response.output.UserJWT;
        
        // return this.token;
      }

      scrollUp() {
        window.scroll({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
         });
        }
    private langCode = new Subject<any>();

    setLanData(data: any) {
      this.langCode.next(data);
      this.currentLang = data.lang;
    }
  
    getLangData(): Observable<any> {
      return this.langCode.asObservable();
    }

    getDate(Date:any,lang:string){
      const array = Date.split("-");
      return {day: array[2], monthYear: `${lang=='ar'? this.monthsArabic[array[1]]:this.monthsEng[array[1]]} ${array[0]}`,month:lang=='ar'? this.monthsArabic[array[1]]:this.monthsEng[array[1]],year:array[0]};
    }

    monthsArabic = {
      '01': 'يناير ',
      '02': 'فبراير  ',
      '03': 'مارس  ',
      '04': 'أبريل  ',
      '05': 'مايو  ',
      '06': 'يونيو ',
      '07': 'يوليو  ',
      '08': 'أغسطس  ',
      '09': 'سبتمبر  ',
      '10': 'أكتوبر  ',
      '11': 'نوفمبر  ',
      '12': 'ديسمبر  ',
    }
    monthsEng = {
      '01': 'Jan ',
      '02': 'Feb',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'Jun',
      '07': 'July',
      '08': 'August',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov  ',
      '12': 'Dec  ',
    }

    fontSizeFactor:any = {value: 0,operation:''};

    changeFont(size){
      if(size == 'add'){
        if(this.fontSizeFactor.value >= 8 ){
          return;
        }
        this.fontSizeFactor.value = this.fontSizeFactor.value+2;
        this.fontSizeFactor.operation = 'add';
      }else{
        if( this.fontSizeFactor.value <= -8){
          return;
        }
        this.fontSizeFactor.value = this.fontSizeFactor.value-2
        this.fontSizeFactor.operation = 'min';
    
    
      }
    
    }

    getGregorianDate(monthNum:number,lang:string){

      return  lang=='ar'? this.monthsGregorianArabic[monthNum]:this.monthsGregorianEng[monthNum] ;
    }

    monthsGregorianArabic = {
      '1': 'يناير',
      '2': 'فبراير',
      '3': 'مارس',
      '4': 'أبريل',
      '5': 'مايو',
      '6': 'يونيو',
      '7': 'يوليو',
      '8': 'أغسطس',
      '9': 'سبتمبر',
      '10': 'أكتوبر',
      '11': 'نوفمبر',
      '12': 'ديسمبر',
    }
    monthsGregorianEng = {
      '1': 'Jan',
      '2': 'Feb',
      '3': 'March',
      '4': 'April',
      '5': 'May',
      '6': 'Jun',
      '7': 'July',
      '8': 'August',
      '9': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec',
    }
    getHijriDate(monthNum:number,lang:string){

      return  lang=='ar'? this.monthsHijriArabic[monthNum]:this.monthsHijriEng[monthNum] ;
    }

    monthsHijriArabic = {
      '1': 'محرم',
      '2': 'صفر',
      '3': 'ربيع الأول',
      '4': 'ربيع الآخر',
      '5': 'جمادى الأولى',
      '6': 'جمادى الآخرة',
      '7': 'رجب',
      '8': 'شعبان',
      '9': 'رمضان',
      '10': 'شوال',
      '11': 'ذو القعدة',
      '12': 'ذو الحجة',
    }
    monthsHijriEng = {
      '1': 'Muharram',
      '2': 'Safar',
      '3': 'Rabi al-awwal',
      '4': 'Rabi al-Thani',
      '5': 'Jumada al-awwal',
      '6': 'Jumada al-Thani',
      '7': 'Rajab',
      '8': 'Shaban',
      '9': 'Ramadan',
      '10': 'Shawwal',
      '11': 'Dhul Qadah',
      '12': 'Dhul Hijjah',
    }
}
